import { Placement } from '@popperjs/core'
import cn from 'classnames'
import React, { MouseEvent, ReactElement, ReactNode, useCallback, useContext, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

import useClickOutside from '@hooks/useClickOutside'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

import '@ui/Tooltip/index.scss'

interface TooltipProps {
  action: 'click' | 'hover' | 'focus'
  content: ReactNode | null
  children: ReactNode
  position: Placement
  useReferenceWidth?: boolean
  disabled?: boolean
  className?: string
  contentClassName?: string
  onOpenChanged?: (isOpened: boolean) => void
  opened?: boolean
  [key: string]: unknown
}

const Tooltip = (props: TooltipProps): ReactElement => {
  const {
    children,
    action,
    content,
    disabled,
    contentClassName,
    className,
    useReferenceWidth,
    position,
    onOpenChanged,
    opened,
    ...rest
  } = props
  const [isShown, setIsShown] = useState<boolean>(false)
  const isPopupVisible = opened ?? isShown
  const container = useContext(PopupContainerContext)

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const popperProps = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  })
  const { styles, attributes, state } = popperProps

  const hideTimeout = useRef<ReturnType<typeof setTimeout>>()

  const handleShownChanged = useCallback(
    (isOpened: boolean): void => {
      if (disabled) return
      clearTimeout(hideTimeout.current)

      if (isOpened) {
        setIsShown(isOpened)
        onOpenChanged?.(isOpened)
      } else {
        hideTimeout.current = setTimeout(() => {
          setIsShown(isOpened)
          onOpenChanged?.(isOpened)
        }, 50)
      }
    },
    [disabled, onOpenChanged],
  )

  useClickOutside([{ current: referenceElement }, { current: popperElement }], () => {
    handleShownChanged(false)
  })

  const toggleTooltip = (): void => handleShownChanged(!isPopupVisible)
  const openTooltip = (): void => handleShownChanged(true)

  const handleShowOnMouseEnter = (): void => {
    handleShownChanged(true)
  }

  const handleCloseOnMouseLeave = (event: MouseEvent): void => {
    if (event.relatedTarget === referenceElement || event.relatedTarget === popperElement) return

    handleShownChanged(false)
  }

  if (content == null) return <>{children}</>

  const popperAlignment = state?.placement.split('-')[0] ?? 'bottom'
  const onReferenceClick = (): void => {
    if (action === 'focus') openTooltip()
    if (action === 'click') toggleTooltip()
  }

  return (
    <>
      <div
        ref={setReferenceElement}
        className={cn('ui-tooltip', className)}
        onMouseEnter={action === 'hover' ? handleShowOnMouseEnter : undefined}
        onMouseLeave={action === 'hover' ? handleCloseOnMouseLeave : undefined}
        onClick={onReferenceClick}
        onFocus={action === 'focus' ? openTooltip : undefined}
        {...rest}
      >
        {children}
      </div>
      {isPopupVisible &&
        !disabled &&
        createPortal(
          <div
            ref={setPopperElement}
            className={cn('ui-tooltip-content', contentClassName, { [popperAlignment]: true })}
            onMouseEnter={action === 'hover' ? handleShowOnMouseEnter : undefined}
            onMouseLeave={action === 'hover' ? handleCloseOnMouseLeave : undefined}
            style={{ ...styles.popper, minWidth: useReferenceWidth ? referenceElement?.offsetWidth : undefined }}
            {...attributes.popper}
          >
            {content}
          </div>,
          container.current ?? /* istanbul ignore next: not reachable */ document.body,
        )}
    </>
  )
}

export default Tooltip
